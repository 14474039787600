import React from 'react';
import { Row, Container, Jumbotron } from 'react-bootstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProjectsSection from '../components/projectSection';

const Projects = () => {
  return (
    <Jumbotron className=" bg-black rounded-0 mb-0 py-5">
      <Container>
        <Row className="justify-content-center">
          <ProjectsSection />
        </Row>
      </Container>
    </Jumbotron>
  );
};

class ProjectsPage extends React.PureComponent {
  render() {
    return (
      <>
        <Layout pageInfo={{ pageName: 'projects' }} title="Our Projects" description="We have worked on several web and mobile based digital products with our clients around the world, we are showcasing a few of them below.">
          <SEO title="Projects" keywords={[`gatsby`, `react`, `bootstrap`]} />
          <Projects />
        </Layout>
      </>
    );
  }
}

export default ProjectsPage;
